/* -------------------------------------------------------------------------- */
/*                                 Theme                                      */
/* -------------------------------------------------------------------------- */



@import "../node_modules/bootstrap/scss/_functions.scss";
@import "scss/theme/functions";

@import "../node_modules/bootstrap/scss/mixins";
@import "scss/theme/mixins";

@import "scss/user-variables";
@import "scss/theme/variables";
@import 'scss/theme/_utilities.scss';
@import "../node_modules/bootstrap/scss/variables";

/*-----------------------------------------------
|   Bootstrap Styles
-----------------------------------------------*/
@import "bootstrap";

/*-----------------------------------------------
|   Theme Styles
-----------------------------------------------*/
@import "scss/theme/theme";

/*-----------------------------------------------
|   User Styles
-----------------------------------------------*/
@import "scss/user";


.iconic-logo {
    color: #ff4500;
    font-size: 1.5rem;
    font-weight: bold;
    font-family: 'Playfair Display';
}

h1 {
    font-family: 'Playfair Display';
}